<template>
  <div id="app">
    <div class="site-contain">
      <!-- <img style="width: 100%;height: auto;" src="https://sns-webpic.xhscdn.com/1040g008314t5l3b3gq0041cfcfi1ctun82167no?imageView2/2/w/0/format/jpg/v3"> -->
      <img style="width: 100%;height: auto;" src="https://xcimg.szwego.com/wsxcWeb/247e0563/o_1i69ce8q69k01ou61fia17101e6a0.jpeg">
        <div class="weichat" @click="go" >
          <img style="border-radius: 50px 50px;width: 90%;" src="./assert/wechat.png">
        </div>
    </div>
  </div>
</template>

<script>

export default {
  name: 'App',
  data(){
    return{
      src:"./assets/image.png"
    }
  },
  methods: {
    go(){
      window.open("https://work.weixin.qq.com/ca/cawcdec1493307809c")
        // document.getElementById("hdf_plug2_openwx").click();
    }
  }
}


</script>

<style>
#app {
  position: relative;
  font-family: Avenir, Helvetica, Arial, sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  text-align: center;
  color: #2c3e50;
}
.site-contain {
    width: 100%;
    max-width: 500px;
    height: auto;
    margin: 0px auto;
    overflow: hidden;
    background-color: #FFFFFF;
}
.weichat {
    position: absolute;
    top: 70%;
    width: 90%;
    max-width: 500px;
    margin: 5%;
    ;
    overflow: hidden;
}
</style>
